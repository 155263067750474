import { axiosInstance, axiosInstanceNews } from "./AxiosConfig";
import { toast } from "react-toastify";

// User sign in API
export const loginApi = async (userid, password) => {
  try {
    const response = await axiosInstance.post("/signin", {
      userid,
      password,
    });

    const now = new Date();
    const expiryDate = new Date(now.getTime() + 5 * 24 * 60 * 60 * 1000); // 5 days from now
    const userData = { ...response.data, UserExpiry: expiryDate };

    return userData;
  } catch (error) {
    // throw error.response.data;
    toast.error(error.response.data);
  }
};

// Add or register user API
export const addUserApi = async (username, password, role, mail) => {
  try {
    const response = await axiosInstance.post("/register", {
      username,
      password,
      role,
      mail,
    });
    return response.data;
  } catch (error) {
    // throw error.response.data;
    toast.error(error.response.data);
  }
};

// Get all available user details API
export const usersInfoApi = async () => {
  try {
    const response = await axiosInstance.get("/user_info");
    return response.data;
  } catch (error) {
    // throw error.response.data;
    toast.error(error.response.data);
  }
};

// Get Details of Specific User API
export const userProfileApi = async (userid) => {
  try {
    const response = await axiosInstance.get(`/user_info?userid=${userid}`);
    return response.data;
  } catch (error) {
    // throw error.response.data;
    toast.error(error.response.data);
  }
};

// Update User Profile Details API
export const updateProfileApi = async (updatedData) => {
  try {
    const response = await axiosInstance.put("/users", updatedData);
    return response.data;
  } catch (error) {
    // throw error.response.data;
    toast.error(error.response.data);
  }
};

// Update User Profile Password API
export const updatePasswordApi = async (updatedData) => {
  try {
    const response = await axiosInstance.put("/users/password", updatedData);
    return response.data;
  } catch (error) {
    // throw error.response.data;
    toast.error(error.response.data);
  }
};

// Delete User API
export const deleteUserApi = async (userid) => {
  try {
    const response = await axiosInstance.post("delete_user", {
      userid,
    });
    return response.data;
  } catch (error) {
    // throw error.response.data;
    toast.error(error.response.data);
  }
};

// Get Funds API
export const fundsApi = async (userid, type, limit) => {
  try {
    const response = await axiosInstance.get(
      `/get_user_funds?userid=${userid}&type=${type}&limit=${limit}`
    );
    return response.data;
  } catch (error) {
    // throw error.response.data;
    toast.error(error.response.data);
  }
};

// Get Latest BSE CA API
export const bseLatestApi = async () => {
  try {
    const response = await axiosInstance.get("/bse/latest");
    return response.data;
  } catch (error) {
    // throw error.response.data;
    toast.error(error.response.data);
  }
};

// Get Latest BSE CA API
export const bseAllNewsApi = async () => {
  try {
    const response = await axiosInstance.get("/bse/all");
    return response.data;
  } catch (error) {
    // throw error.response.data;
    toast.error(error.response.data);
  }
};

// Get Latest NSE CA API
export const nseAnnApi = async () => {
  try {
    const response = await axiosInstance.get("/nse/ann");
    return response.data;
  } catch (error) {
    // throw error.response.data;
    toast.error(error.response.data);
  }
};

// Get User enabled accounts API
export const userIdEnabledAccountApi = async () => {
  try {
    const response = await axiosInstance.get("/userid_enabled_ac");
    return response.data;
  } catch (error) {
    // throw error.response.data;
    toast.error(error.response.data);
  }
};

// Add user enabled accounts api
export const addUserIdEnabledAccountApi = async (accountid, userid) => {
  try {
    const response = await axiosInstance.post("/userid_enabled_ac", {
      accountid,
      userid,
    });
    return response.data;
  } catch (error) {
    // throw error.response.data;
    toast.error(error.response.data);
  }
};

// Delete User enabled accounts API
export const deleteUserIdEnabledAccountApi = async (id) => {
  try {
    const response = await axiosInstance.post("/userid_enabled_ac/delete", {
      id,
    });
    return response.data;
  } catch (error) {
    // throw error.response.data;
    toast.error(error.response.data);
  }
};

// Delete all user enabled accounts api
export const deleteAllUserIdEnabledAccountApi = async () => {
  try {
    const response = await axiosInstance.delete("/userid_enabled_ac/all");
    return response.data;
  } catch (error) {
    toast.error(error.response.data);
  }
};

// Get user enabled News API
export const userEnabledNewsApi = async () => {
  try {
    const response = await axiosInstance.get("/userid_enabled_news");
    return response.data;
  } catch (error) {
    // throw error.response.data;
    toast.error(error.response.data);
  }
};

// Add user enabled News API
export const addUserEnabledNewsApi = async (symbol, userid) => {
  try {
    const response = await axiosInstance.post("/userid_enabled_news", {
      symbol,
      userid,
    });
    return response.data;
  } catch (error) {
    // throw error.response.data;
    toast.error(error.response.data);
  }
};

// Delete user enabled news API
export const deleteUserEnabledNewsApi = async (symbol, userid) => {
  try {
    const response = await axiosInstance.delete(
      `/userid_enabled_news?userid=${userid}&symbol=${symbol}`
    );
    return response.data;
  } catch (error) {
    // throw error.response.data;
    toast.error(error.response.data);
  }
};

// GET NSE Annoucemenets API
export const nseAnnouncementsApi = async (symbol) => {
  try {
    const response = await axiosInstanceNews.get(`/ann?symbol=${symbol}`);
    return response.data;
  } catch (error) {
    // throw error.response.data;
    toast.error(error.response.data);
  }
};

// GET Symbols & Scripcodes of User
export const getSymbolsApi = async (userid, userrole) => {
  try {
    const response = await axiosInstance.get(
      `/symbol?userid=${userid}&role=${userrole}`
    );
    return response.data;
  } catch (error) {
    // throw error.response.data;
    toast.error(error.response.data);
  }
};


// GET Symbols in Stock Exchange
export const getAllSymbolsApi = async () => {
  try {
    const response = await axiosInstance.get("/symbols-list");
    return response.data;
  } catch (error) {
    // throw error.response.data;
    toast.error(error.response.data);
  }
};

// Add News API
export const addNewsApi = async (
  subcatname,
  newssub,
  symbol,
  attachmentname,
  description,
  userid
) => {
  try {
    const response = await axiosInstance.post("/newsdata/add", {
      subcatname,
      newssub,
      symbol,
      attachmentname,
      description,
      userid,
    });
    return response.data;
  } catch (error) {
    // throw error.response.data;
    toast.error(error.response.data);
  }
};

// Get News API
export const getNewsApi = async () => {
  try {
    const response = await axiosInstance.get("/newsdata/get");
    return response.data;
  } catch (error) {
    // throw error.response.data;
    toast.error(error.response.data);
  }
};

// Get Sector News API
export const getSectorNewsApi = async () => {
  try {
    const response = await axiosInstance.get("/sector/latest");
    return response.data;
  } catch (error) {
    // throw error.response.data;
    toast.error(error.response.data);
  }
};


// Get positions in News API
export const getNewsPositionsApi = async (scripcode, userid) => {
  try {
    let intScripcode = parseInt(scripcode, 10);
    const response = await axiosInstance.post("/positions/get", {
      scripcode: intScripcode,
      userid: userid,
    });
    return response.data;
  } catch (error) {
    // throw error.response.data;
    toast.error(error.response.data);
  }
};

// Get News Historical Data API
export const getNewsHistoricalDataApi = async (scripcode) => {
  try {
    const response = await axiosInstance.get(`/ann?scripcode=${scripcode}`);
    return response.data;
  } catch (error) {
    // throw error.response.data;
    toast.error(error.response.data);
  }
};

//Upload file API
export const uploadFileApi = async (file) => {
  const formData = new FormData();
  formData.append('file', file);

  try {
    const response = await axiosInstance.post('/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    toast.error(error.response.data);
  }
};
