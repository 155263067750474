import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Sidebar from "./Sidebar.jsx";
import { updateProfileApi } from "../utilities/Api.js";
import Loader from "./Loader.jsx";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import ProfileCSS from "../css/Profile.module.css";

function ProfileEditDetails() {
  const location = useLocation();
  const { localStorageKey, navigateOnSuccess, navigateOnBackButton } =
    location.state;
  const navigate = useNavigate();
  const profileData = JSON.parse(localStorage.getItem(`${localStorageKey}`));
  const userDataFromUser = JSON.parse(localStorage.getItem("user"));
  const USERID = profileData ? profileData.UserId : userDataFromUser.UserId;

  const USERNAME = profileData
    ? profileData.UserName
    : userDataFromUser.UserName;

  const USERROLE = profileData
    ? profileData.UserRole
    : userDataFromUser.UserRole;

  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    UserName: "",
    UserMail: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Check if either username or email is entered
    if (!formData.UserName && !formData.UserMail) {
      toast.error("Please enter either username or email.");
      setIsLoading(false);
      return; // Exit the function without further execution
    }

    // Check if email is entered and validate it
    if (formData.UserMail && !validateEmail(formData.UserMail)) {
      toast.error("Please enter a valid email address.");
      setIsLoading(false);
      return; // Exit the function without further execution
    }

    try {
      const requestBody = {
        userid: USERID,
        user_data: {
          UserName: formData.UserName || profileData.UserName,
          UserMail: formData.UserMail || profileData.UserMail,
          UserRole: USERROLE,
        },
      };

      await updateProfileApi(requestBody);
      navigate(`${navigateOnSuccess}`);
      toast.success("Profile updated successfully!");
    } catch (error) {
      toast.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Function to validate email format
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  function goBack() {
    navigate(`${navigateOnBackButton}`);
  }

  return (
    <div className="MainDiv">
      <Sidebar />
      <div className="MainDivContent">
        <div className={ProfileCSS.Container}></div>
        {isLoading ? (
          <Loader />
        ) : (
          <div>
            <h1>
              <FontAwesomeIcon
                icon={faPenToSquare}
                style={{ marginRight: "5px" }}
              />
              Edit Profile Details
            </h1>
            <form onSubmit={handleSubmit}>
              {/* user id */}
              <label className={ProfileCSS.label}>
                User Id
                <input
                  type="text"
                  name="UserId"
                  placeholder={USERID}
                  value={USERID}
                  disabled
                  className={ProfileCSS.input}
                />
              </label>
              {/* user role */}
              <label className={ProfileCSS.label}>
                User Role
                <input
                  type="text"
                  name="UserRole"
                  placeholder={USERROLE}
                  value={USERROLE}
                  disabled
                  className={ProfileCSS.input}
                />
              </label>
              {/* user name */}
              <label className={ProfileCSS.label}>
                User Name
                <input
                  type="text"
                  name="UserName"
                  placeholder={USERNAME}
                  value={formData.UserName}
                  onChange={handleChange}
                  className={ProfileCSS.input}
                />
              </label>
              {/* user email */}
              <label className={ProfileCSS.label}>
                User Email
                <input
                  type="email"
                  name="UserMail"
                  placeholder={profileData.UserMail}
                  value={formData.UserMail}
                  onChange={handleChange}
                  className={ProfileCSS.input}
                />
              </label>
              <button
                type="submit"
                className={ProfileCSS.btn}
                style={{ marginTop: "15px" }}
              >
                Update Profile
              </button>
              <button
                className={ProfileCSS.btn}
                style={{ marginTop: "15px" }}
                onClick={goBack}
              >
                Go Back
              </button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
}

export default ProfileEditDetails;
