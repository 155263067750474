import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Sidebar from "./Sidebar.jsx";
import { updatePasswordApi } from "../utilities/Api.js";
import { logout } from "../utilities/UtilityFunctions.js";
import Loader from "./Loader.jsx";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import ProfileCSS from "../css/Profile.module.css";

function ProfileEditPassword() {
  const location = useLocation();
  const { localStorageKey, logoutOnSuccess, navigateOnBackButton } =
    location.state;
  const navigate = useNavigate();
  const profileData = JSON.parse(localStorage.getItem(`${localStorageKey}`));
  const userDataFromUser = JSON.parse(localStorage.getItem("user"));
  const USERID = profileData ? profileData.UserId : userDataFromUser.UserId;
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    UserPassword: "",
    ReUserPassword: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Check if both password fields are filled
    if (!formData.UserPassword || !formData.ReUserPassword) {
      toast.error("Please enter both passwords.");
      setIsLoading(false);
      return;
    }

    // Check if both passwords match
    if (formData.UserPassword !== formData.ReUserPassword) {
      toast.error("Entered passwords do not match.");
      setIsLoading(false);
      return;
    }

    try {
      const requestBody = {
        userid: USERID,
        new_password: formData.UserPassword,
      };

      await updatePasswordApi(requestBody);
      if (logoutOnSuccess) {
        logout(navigate, false, "Password updated please login again!");
      } else {
        navigate(`${navigateOnBackButton}`);
        toast.success("Password updated successfully!");
      }
    } catch (error) {
      toast.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  function goBack() {
    navigate(`${navigateOnBackButton}`);
  }

  return (
    <div className="MainDiv">
      <Sidebar />
      <div className="MainDivContent">
        <div className={ProfileCSS.Container}></div>
        {isLoading ? (
          <Loader />
        ) : (
          <div>
            <h1>
              <FontAwesomeIcon
                icon={faPenToSquare}
                style={{ marginRight: "5px" }}
              />
              Edit Profile Password
            </h1>
            <form onSubmit={handleSubmit}>
              {/* New Password */}
              <label className={ProfileCSS.label}>
                New Password
                <input
                  type="password"
                  name="UserPassword"
                  placeholder="Enter new password"
                  value={formData.UserPassword}
                  className={ProfileCSS.input}
                  onChange={handleChange}
                />
              </label>
              {/* Re enter new password */}
              <label className={ProfileCSS.label}>
                Re Enter New Password
                <input
                  type="password"
                  name="ReUserPassword"
                  placeholder="Re enter new password"
                  value={formData.ReUserPassword}
                  className={ProfileCSS.input}
                  onChange={handleChange}
                />
              </label>
              <button
                type="submit"
                className={ProfileCSS.btn}
                style={{ marginTop: "15px" }}
              >
                Update Password
              </button>
              <button
                className={ProfileCSS.btn}
                style={{ marginTop: "15px" }}
                onClick={goBack}
              >
                Go Back
              </button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
}

export default ProfileEditPassword;
