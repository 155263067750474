import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LogViewer from "./components/LogViewer";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Profile from "./pages/Profile";
import ProfileEditDetails from "./components/UserEditDetails";
import ProfileEditPassword from "./components/UserEditPassword";
import UserEditDetails from "./components/UserEditDetails";
import UserEditPassword from "./components/UserEditPassword";
import Funds from "./pages/Funds";
import News from "./pages/News";
import Gnews from "./pages/Gnews";
import Users from "./pages/Users";
import AddUsers from "./pages/AddUsers";
import Assign from "./pages/Assign";
import ProtectedRoute from "./components/ProtectedRoute";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/log"
            element={
              <ProtectedRoute>
                <LogViewer />
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile/edit/:id"
            element={
              <ProtectedRoute>
                <ProfileEditDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile/change-password/:id"
            element={
              <ProtectedRoute>
                <ProfileEditPassword />
              </ProtectedRoute>
            }
          />
          <Route
            path="/users/edit/:id"
            element={
              <ProtectedRoute>
                <UserEditDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="/users/change-password/:id"
            element={
              <ProtectedRoute>
                <UserEditPassword />
              </ProtectedRoute>
            }
          />
          <Route
            path="/users/add"
            element={
              <ProtectedRoute>
                <AddUsers />
              </ProtectedRoute>
            }
          />
          <Route
            path="/funds"
            element={
              <ProtectedRoute>
                <Funds />
              </ProtectedRoute>
            }
          />
          <Route
            path="/news"
            element={
              <ProtectedRoute>
                <News />
              </ProtectedRoute>
            }
          />
          <Route
            path="/gnews"
            element={
              <ProtectedRoute>
                <Gnews />
              </ProtectedRoute>
            }
          />
          <Route
            path="/users"
            element={
              <ProtectedRoute>
                <Users />
              </ProtectedRoute>
            }
          />
          <Route
            path="/assign"
            element={
              <ProtectedRoute>
                <Assign />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router>
      <ToastContainer />
    </>
  );
}

export default App;
