import React, { useState, useEffect } from "react";
import {
  bseLatestApi,
  getNewsApi,
  getSymbolsApi,
  getNewsPositionsApi,
  getNewsHistoricalDataApi,
} from "../utilities/Api";
import { toast } from "react-toastify";
import DashNews from "../css/DashNews.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTags } from "@fortawesome/free-solid-svg-icons";
import Loader from "../components/Loader";
import { v4 as uuidv4 } from "uuid";
import { excludedSubcategories } from "../utilities/UtilityFunctions";
import NewsCSS from "../css/News.module.css";
import ConfirmationModal from "../components/ConfirmationModal";

function DashResearchNews() {
  const [newsData, setNewsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Set to true initially
  const [isPositionsModalOpen, setIsPositionsModalOpen] = useState(false);
  const [isPositionsModalApiData, setIsPositionsModalApiData] = useState([]);
  const [isHistoricalModalOpen, setIsHistoricalModalOpen] = useState(false);
  const [isHistoricalModalApiData, setIsHistoricalModalApiData] = useState([]);
  const [scripcodes, setScripcodes] = useState({});
  const userData = JSON.parse(localStorage.getItem("user"));
  const profileData = JSON.parse(localStorage.getItem("profile"));
  const USERID = userData.UserId;
  const USERROLE =
    profileData && userData ? profileData.UserRole : userData.UserRole;
  const isAdminOrResearch = USERROLE === "ADMIN" || USERROLE === "RESEARCHER";
  const isDealer = USERROLE === "DEALER";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await bseLatestApi();
        const response1 = await getNewsApi();
        const symbolsResponse = await getSymbolsApi(USERID, USERROLE);

        const updatedResponse = response.Table.map((item) => ({
          ...item,
          ATTACHMENTNAME: `${item.ATTACHMENTNAME}`,
        }));

        const mergedData = [...updatedResponse, ...response1];

        mergedData.sort(
          (a, b) =>
            new Date(b.News_submission_dt) - new Date(a.News_submission_dt)
        );

        setNewsData(mergedData);
        setScripcodes(symbolsResponse.results);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        toast.error("Error fetching data:", error);
      } finally {
        setTimeout(fetchData, 10000);
      }
    };

    fetchData();

    return () => clearTimeout();
  }, [USERID, USERROLE]);

  const openPositionsModal = async (scripcode, USERID) => {
    setIsPositionsModalOpen(true);
    setIsLoading(true);
    try {
      const response = await getNewsPositionsApi(scripcode, USERID);
      if (
        !response ||
        (response.cash_positions.length === 0 &&
          response.nse_fo_positions.length === 0)
      ) {
        toast.error("No Active Positions for this.");
      }
      setIsPositionsModalApiData(response.cash_positions);
    } catch (error) {
      toast.error("Error fetching positions:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const openHistoricalModal = async (scripcode) => {
    setIsHistoricalModalOpen(true);
    setIsLoading(true);
    try {
      const response = await getNewsHistoricalDataApi(scripcode);
      setIsHistoricalModalApiData(response.results.Table);
    } catch (error) {
      toast.error("Error fetching historical data:", error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div>
      {isLoading && <Loader />}
      {!isLoading && newsData.length === 0 && (
        <p
          style={{
            fontSize: "26px",
            fontWeight: "bold",
          }}
        >
          No news to display!
        </p>
      )}
      <div className={DashNews.newsCardContainer}>
        {newsData.map((newsItem) => {
          const scripcodesValues = Object.values(scripcodes);
          const shouldDisplayNews =
            scripcodesValues.includes(newsItem.SCRIP_CD.toString()) &&
            !excludedSubcategories.includes(newsItem.SUBCATNAME);

          if (
            (isAdminOrResearch && shouldDisplayNews) ||
            (isDealer && shouldDisplayNews)
          ) {
            const uniqueKey = uuidv4();
            return (
              <div key={uniqueKey} className={DashNews.newsCard}>
                <div className={DashNews.newsCardContent}>
                  <a
                    href={`${newsItem.ATTACHMENTNAME}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <h2 className={DashNews.slogname}>{newsItem.NEWSSUB}</h2>
                  </a>
                  <p className={DashNews.category}>
                    {newsItem.SUBCATNAME}
                    <FontAwesomeIcon
                      icon={faTags}
                      style={{ marginLeft: "5px" }}
                    />
                     &nbsp;&nbsp; <b className={NewsCSS.company}>{newsItem.COMPANYNAME}</b>
                  </p>
                  <p className={NewsCSS.category}>{newsItem.DESCRIPTION}</p>
                  <button
                    className={DashNews.positionsBtn}
                    onClick={() =>
                      openPositionsModal(newsItem.SCRIP_CD, USERID)
                    }
                  >
                    <b>Positions</b>
                  </button>
                  <button
                    className={DashNews.historicalBtn}
                    onClick={() => openHistoricalModal(newsItem.SCRIP_CD)}
                  >
                    <b>Historical Data</b>
                  </button>
                  <p className={DashNews.dateTimeAndScripCode}>
                    <b>Date & Time:</b>&nbsp; {newsItem.News_submission_dt}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <b>Scripcode:</b>&nbsp;{newsItem.SCRIP_CD}
                  </p>
                </div>
              </div>
            );
          } else {
            return null;
          }
        })}
      </div>

      {/* Positions Modal */}
      <ConfirmationModal
        isOpen={isPositionsModalOpen}
        onClose={() => setIsPositionsModalOpen(false)}
        questionText="Positions"
        confirmText="Close"
        onConfirm={() => setIsPositionsModalOpen(false)}
        height={"500"}
        width={"900"}
      >
        {isLoading ? (
          <Loader />
        ) : (
          <div>
            <div className={NewsCSS.modalNewsCardContainer}>
              {isPositionsModalApiData.map((newsPositionsItem) => {
                const uniqueKeyForPositionsModal = uuidv4();
                return (
                  <div
                    key={uniqueKeyForPositionsModal}
                    className={NewsCSS.newsCard}
                  >
                    <div className={NewsCSS.newsCardContent}>
                      <p>Account Name: {newsPositionsItem.AccountName}</p>
                      <p>Buy Average Price: {newsPositionsItem.BuyAvgPrice}</p>
                      <p>
                        Quantity Remaining:
                        {newsPositionsItem.quantity_remaining}
                      </p>
                      <p>Realised Pl: {newsPositionsItem.realised_pl}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </ConfirmationModal>

      <ConfirmationModal
        isOpen={isHistoricalModalOpen}
        className="modalContent"
        onClose={() => setIsHistoricalModalOpen(false)}
        questionText="Historical Data"
        confirmText="Close"
        onConfirm={() => setIsHistoricalModalOpen(false)}
        height={"500"}
        width={"900"}
      >
        {isLoading ? (
          <Loader />
        ) : (
          <div>
            <div className={NewsCSS.modalNewsCardContainer}>
              {isHistoricalModalApiData.map((newsHistoricalItem) => {
                const uniqueKeyForHistoricalModal = uuidv4();
                return (
                  <div
                    key={uniqueKeyForHistoricalModal}
                    className={NewsCSS.newsCard}
                  >
                    <div className={NewsCSS.newsCardContent}>
                      <a
                        href={`https://www.bseindia.com/xml-data/corpfiling/AttachLive/${newsHistoricalItem.ATTACHMENTNAME}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <h2 className={NewsCSS.slogname}>
                          {newsHistoricalItem.NEWSSUB}
                        </h2>
                      </a>
                      <p className={NewsCSS.category}>
                        {newsHistoricalItem.SUBCATNAME}
                        <FontAwesomeIcon
                          icon={faTags}
                          style={{ marginLeft: "5px" }}
                        />
                      </p>
                      <p className={NewsCSS.dateTimeAndScripCode}>
                        <b>Date & Time:</b>&nbsp;{" "}
                        {newsHistoricalItem.News_submission_dt}
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <b>Scripcode:</b>&nbsp;{newsHistoricalItem.SCRIP_CD}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </ConfirmationModal>
    </div>
  );
}

export default DashResearchNews;
