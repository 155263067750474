class Logger {
    static logLevel = 'info';
  
    static logLevels = ['trace', 'debug', 'info', 'warn', 'error'];
  
    static log(message, level = 'info') {
      if (Logger.logLevels.indexOf(level) >= Logger.logLevels.indexOf(Logger.logLevel)) {
        const logs = JSON.parse(localStorage.getItem('logs')) || [];
        const logEntry = {
          timestamp: new Date().toISOString(),
          level,
          message,
        };
        logs.push(logEntry);
        localStorage.setItem('logs', JSON.stringify(logs));
      }
    }
  
    static trace(message) {
      Logger.log(message, 'trace');
    }
  
    static debug(message) {
      Logger.log(message, 'debug');
    }
  
    static info(message) {
      Logger.log(message, 'info');
    }
  
    static warn(message) {
      Logger.log(message, 'warn');
    }
  
    static error(message) {
      Logger.log(message, 'error');
    }
  
    static getLogs() {
      return JSON.parse(localStorage.getItem('logs')) || [];
    }
  
    static clearLogs() {
      localStorage.removeItem('logs');
    }
  }
  
  export default Logger;
  