import React, { useEffect, useState } from "react";
import Logger from "../utilities/Logger.js";
import Sidebar from "../components/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import AddUsersCSS from "../css/Users.module.css";
import Loader from "../components/Loader.jsx";
import { addUserApi } from "../utilities/Api.js";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function AddUsers() {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    UserRole: "",
    UserName: "",
    UserPassword: "",
    ReUserPassword: "",
    UserMail: "",
  });

  const navigate = useNavigate();
  const { UserRole, UserName, UserPassword, UserMail } = formData;
  const userData = JSON.parse(localStorage.getItem("user"));
  const USERID = userData.UserId;
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    document.documentElement.style.setProperty(
      '--sidebar-width',
      isOpen ? '250px' : '50px'
    );
    }, [isOpen]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Check if all fields are filled
    if (
      !formData.UserRole ||
      !formData.UserName ||
      !formData.UserPassword ||
      !formData.ReUserPassword ||
      !formData.UserMail
    ) {
      toast.error("Please enter all fields.");
      setIsLoading(false);
      return;
    }

    // Check if both passwords match
    if (formData.UserPassword !== formData.ReUserPassword) {
      toast.error("Entered passwords do not match.");
      setIsLoading(false);
      return;
    }

    // Add user API call
    try {
      const response = await addUserApi(
        UserName,
        UserPassword,
        UserRole,
        UserMail
      );
      if (response && response.userid) {
        toast.success(`User ${response.userid} created successfully!`);
        Logger.info(`User "${UserName}" created by "${USERID}"`);
        navigate("/users");

      }
      else if(
        response &&
        response.status === 400 &&
        response.message === "Password must be at least 8 characters long"
      ){
        toast.error("Password must be at least 8 characters long");
      } else if (
        response &&
        response.status === 400 &&
        response.message === "Email is already associated with an account"
      ) {
        toast.error("Email is already associated with an account");
      } else {
        toast.error("User creation failed!");
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wrong. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  function goBack() {
    navigate("/users");
  }
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="MainDiv">
      <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
      <div className="MainDivContent">
        <h1 style={{ margin: "12px 0px 20px 0px" }}>
          <FontAwesomeIcon icon={faUserPlus} style={{ marginRight: "5px" }} />
          Add Users
        </h1>
        <p>Please enter the following details to create a user.</p>
        {isLoading ? (
          <Loader />
        ) : (
          <div>
            <form onSubmit={handleSubmit}>
              {/* User Role Dropdown */}
              <label className={AddUsersCSS.label}>
                User Role
                <select
                  name="UserRole"
                  value={formData.UserRole}
                  className={AddUsersCSS.dropdown}
                  onChange={handleChange}
                >
                  <option value="" className={AddUsersCSS.options}>
                    Select User Role
                  </option>
                  <option value="ADMIN" className={AddUsersCSS.options}>
                    ADMIN
                  </option>
                  <option value="RESEARCHER" className={AddUsersCSS.options}>
                    RESEARCHER
                  </option>
                  <option value="DEALER" className={AddUsersCSS.options}>
                    DEALER
                  </option>
                </select>
              </label>
              {/* User Name */}
              <label className={AddUsersCSS.label}>
                User Name
                <input
                  type="text"
                  name="UserName"
                  placeholder="Enter user name"
                  value={formData.UserName}
                  className={AddUsersCSS.input}
                  onChange={handleChange}
                />
              </label>
              {/* User Password */}
              <label className={AddUsersCSS.label}>
                User Password
                <input
                  type="password"
                  name="UserPassword"
                  placeholder="Enter user password"
                  value={formData.UserPassword}
                  className={AddUsersCSS.input}
                  onChange={handleChange}
                />
              </label>
              {/* Re-enter User Password */}
              <label className={AddUsersCSS.label}>
                Re-enter User Password
                <input
                  type="password"
                  name="ReUserPassword"
                  placeholder="Re-enter user password"
                  value={formData.ReUserPassword}
                  className={AddUsersCSS.input}
                  onChange={handleChange}
                />
              </label>
              {/* User Email */}
              <label className={AddUsersCSS.label}>
                User Email
                <input
                  type="email"
                  name="UserMail"
                  placeholder="Enter user email address"
                  value={formData.UserMail}
                  className={AddUsersCSS.input}
                  onChange={handleChange}
                />
              </label>
              <button
                type="submit"
                className={AddUsersCSS.addUserbtn}
                style={{ marginTop: "15px" }}
              >
                Create User
              </button>
              <button
                className={AddUsersCSS.addUserbtn}
                style={{ marginTop: "15px" }}
                onClick={goBack}
              >
                Go Back
              </button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
}

export default AddUsers;
