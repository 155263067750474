import React, { useEffect, useState } from 'react';
import Logger from '../utilities/Logger';

const LogViewer = () => {
  const [logs, setLogs] = useState([]);
  const userData = JSON.parse(localStorage.getItem("user"));
  const profileData = JSON.parse(localStorage.getItem("profile"));
  const USERROLE = profileData && userData ? profileData.UserRole : userData.UserRole;
  const isAdmin = USERROLE === "ADMIN";
  
  useEffect(() => {
    if (isAdmin) {
      setLogs(Logger.getLogs());
    }
  }, [isAdmin]);

  const downloadLogs = () => {
    const logString = logs.map(log => `[${log.timestamp}] ${log.level.toUpperCase()}: ${log.message}`).join('\n');
    const blob = new Blob([logString], { type: 'text/plain' });
    const href = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = 'logs.txt';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  };


  const styles = {
    container: {
      padding: '20px',
      maxWidth: '800px',
      margin: 'auto',
      fontFamily: '"Segoe UI", Arial, sans-serif',
    },
    header: {
      fontSize: '24px',
      marginBottom: '20px',
    },
    list: {
      listStyleType: 'none',
      paddingLeft: '0',
    },
    listItem: {
      background: '#f0f0f0',
      margin: '5px 0',
      padding: '10px',
      borderRadius: '5px',
    },
    button: {
      padding: '10px 20px',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      backgroundColor: '#059346',
      color: 'white',
      fontSize: '16px',
      marginTop: '20px',
      marginLeft: '10px'
    },
    unauthorized: {
      textAlign: 'center',
      fontSize: '18px',
      color: 'red',
    },
  };

  return (
    <div style={styles.container}>
      {isAdmin ? (
        <>
          <h2 style={styles.header}>Logs: </h2>
          <ul style={styles.list}>
            {logs.map((log, index) => (
              <li key={index} style={styles.listItem}>
                [{log.timestamp}] {log.level.toUpperCase()}: {log.message}
              </li>
            ))}
          </ul>
          <button style={styles.button} onClick={() => Logger.clearLogs() || setLogs([])}>Clear Logs</button>
          <button style={styles.button} onClick={downloadLogs}>Download Logs</button>
        </>
      ) : (
        <div style={styles.unauthorized}>You are not authorised to see this page.</div>
      )}
    </div>
  );
};


export default LogViewer;