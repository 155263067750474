import React, {useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Sidebar from "../components/Sidebar";
import DashboardCSS from "../css/Dashboard.module.css";
import DashSamvittiNews from "../components/DashSamvittiNews";
import DashGlobalNews from "../components/DashGlobalNews";
import DashResearchnews from "../components/DashResearchNews";
import DashSectorNews from "../components/DashSectorNews";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faNewspaper, faGlobe } from "@fortawesome/free-solid-svg-icons";

function Home() {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    document.documentElement.style.setProperty(
      '--sidebar-width',
      isOpen ? '250px' : '50px'
    );
    const now = new Date();
    const userData = JSON.parse(localStorage.getItem("user"));
    if (userData) {
      const expiryDate = new Date(userData.expiry);
      if (now > expiryDate) {
        logout();
      }
    }
    // eslint-disable-next-line
  }, [isOpen]);

  function logout() {
    localStorage.removeItem("user");
    navigate("/");
    toast.success("Logout successful!");
  }

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };


  return (
    <div className="MainDiv">
      <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
      <div className="MainDivContent">
        <div className={DashboardCSS.container}>
          <div className={DashboardCSS.section1}>
            <div className={DashboardCSS.section1Part}>
              <h2>
                <FontAwesomeIcon
                  icon={faNewspaper}
                  style={{ marginRight: "10px" }}
                />
                Research News
              </h2>
              <DashSamvittiNews/>
            </div>
            <div className={DashboardCSS.section1Part}>
              <h2>
                <FontAwesomeIcon
                  icon={faNewspaper}
                  style={{ marginRight: "10px" }}
                />
                Sector News
              </h2>
              <DashSectorNews />
            </div>
          </div>
          {/* <div className={DashboardCSS.divider}></div> */}
          <div className={DashboardCSS.section2}>
            <h2>
              <FontAwesomeIcon icon={faGlobe} style={{ marginRight: "10px" }} />
              Fund News
            </h2>
            <DashResearchnews />
          </div>
          <div className={DashboardCSS.section2}>
            <h2>
              <FontAwesomeIcon icon={faGlobe} style={{ marginRight: "10px" }} />
              Global News
            </h2>
            <DashGlobalNews />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
