import { toast } from "react-toastify";

// Function to logout
export const logout = (navigate, defaultMessage = true, customMessage = "") => {
  clearLocalStorage();
  navigate("/");

  if (defaultMessage) {
    toast.success("Logout successful!");
  } else {
    toast.success(customMessage);
  }
};

// Function to clear the entire local storage
export const clearLocalStorage = () => {
  localStorage.clear();
};

// Function to remove a specific key from local storage
export const removeKeyDataFromLocalStorage = (key) => {
  localStorage.removeItem(key);
};

export const excludedSubcategories = [
  "57 (5) : intimation after the end of quarter",
  "AGM",
  "Allotment of ESOP / ESPS",
  "Analyst / Investor Meet",
  "Appointment of Company Secretary / Compliance Officer",
  "Book Closure",
  "Business Responsibility and Sustainability Reporting (BRSR)",
  "Cessation",
  "Change in Directorate",
  "Change in Directors/ Key Managerial Personnel/ Auditor/ Compliance Officer/ Share Transfer Agent",
  "Change in Registered Office Address",
  "Closure of Trading Window",
  "Credit Rating",
  "Demise",
  "Disclosures by listed entities of defaults on payment of interest/ repayment of principal amount for loans including revolving facilities like cash credit from banks / financial institutions.",
  "Disclosures under Reg. 10(5) in respect of acquisition under Reg. 10(1)(a) of SEBI (SAST) Reg. 2011",
  "Diversification / Disinvestment",
  "Dividend",
  "EGM",
  "Newspaper Publication",
  "Others",
  "Outcome without intimation",
  "Postal Ballot",
  "Record Date",
  "Reg. 34 (1) Annual Report",
  "Reg. 39 (3) - Details of Loss of Certificate / Duplicate Certificate",
  "Reg. 40 (10) - PCS Certificate for Transfer / Transmission / Transposition",
  "Reg. 50 (1) - Prior intimation about Board meeting under Regulation 50(1))",
  "Loss of Share Certificate_Issue of Duplicate Share Certificate or Letter of Confirmation",
  "Loss of Share Certificate and Issue of Duplicate Share Certificate or Letter of Confirmation",
  "Disclosures under Reg. 31(1) and 31(2) of SEBI (SAST) Regulations",
  "Certificate under Reg. 74 (5) of SEBI (DP) Regulation",
  "Reg. 32 (1)",
];