import React, { useState } from "react";
import SidebarCSS from "../css/Sidebar.module.css";
import { useNavigate, useLocation } from "react-router-dom";
import { logout } from "../utilities/UtilityFunctions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartSimple,
  faSackDollar,
  faUserGroup,
  faRightFromBracket,
  faNewspaper,
  faAddressCard,
  faUserTag,
  faGlobe,
  faAnglesLeft,
  faAnglesRight,
} from "@fortawesome/free-solid-svg-icons";
import ProfileImg from "../assets/logo.png";

function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(true);

  function handleLogout() {
    logout(navigate);
  }

  function handleDashboard() {
    navigate("/dashboard");
  }

  function handleFunds() {
    navigate("/funds");
  }

  function handleNews() {
    navigate("/news");
  }

  function handleGnews() {
    navigate("/gnews");
  }

  function handleUsers() {
    navigate("/users");
  }

  function handleProfile() {
    navigate("/profile");
  }

  function handleAssign() {
    navigate("/assign");
  }

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
    document.documentElement.style.setProperty(
      '--sidebar-width',
      isOpen ? 'var(--sidebar-width-closed)' : 'var(--sidebar-width-open)'
    );
  };

  const userDataFromUser = JSON.parse(localStorage.getItem("user")) || {};
  const userDataFromProfile = JSON.parse(localStorage.getItem("profile")) || {};
  const USERNAME = userDataFromProfile
    ? userDataFromProfile.UserName
    : userDataFromUser.UserName;
  const USERROLE = userDataFromProfile
    ? userDataFromProfile.UserRole
    : userDataFromUser.UserRole;

  return (
    <div
      className={`${SidebarCSS.sidebar} ${
        isOpen ? SidebarCSS.open : SidebarCSS.closed
      }`}
    >
      {isOpen && (
        <div className={SidebarCSS.profileDiv}>
          <img
            src={ProfileImg}
            alt="profile"
            className={SidebarCSS.profileImg}
          />
          <p className={SidebarCSS.profileRole}>{USERROLE}</p>
          <p className={SidebarCSS.profileUserName}>{USERNAME}</p>
        </div>
      )}
      <ul className={`${SidebarCSS.ul} ${!isOpen ? SidebarCSS.ulClosed : ""}`}>
        <li className={SidebarCSS.li}>
          <button
            className={`${SidebarCSS.navBtn} ${
              location.pathname.startsWith("/dashboard")
                ? SidebarCSS.navBtnActive
                : ""
            }`}
            onClick={handleDashboard}
          >
            <FontAwesomeIcon
              icon={faChartSimple}
              style={{ marginRight: "10px" }}
            />
            {isOpen && "Dashboard"}
          </button>
        </li>
        <li className={SidebarCSS.li}>
          <button
            className={`${SidebarCSS.navBtn} ${
              location.pathname.startsWith("/funds")
                ? SidebarCSS.navBtnActive
                : ""
            }`}
            onClick={handleFunds}
          >
            <FontAwesomeIcon
              icon={faSackDollar}
              style={{ marginRight: "10px" }}
            />
            {isOpen && "Funds"}
          </button>
        </li>
        <li className={SidebarCSS.li}>
          <button
            className={`${SidebarCSS.navBtn} ${
              location.pathname.startsWith("/news")
                ? SidebarCSS.navBtnActive
                : ""
            }`}
            onClick={handleNews}
          >
            <FontAwesomeIcon
              icon={faNewspaper}
              style={{ marginRight: "10px" }}
            />
            {isOpen && "Fund News"}
          </button>
        </li>
        <li className={SidebarCSS.li}>
          <button
            className={`${SidebarCSS.navBtn} ${
              location.pathname.startsWith("/gnews")
                ? SidebarCSS.navBtnActive
                : ""
            }`}
            onClick={handleGnews}
          >
            <FontAwesomeIcon icon={faGlobe} style={{ marginRight: "10px" }} />
            {isOpen && "Global News"}
          </button>
        </li>
        {USERROLE === "ADMIN" && (
          <React.Fragment>
            <li className={SidebarCSS.li}>
              <button
                className={`${SidebarCSS.navBtn} ${
                  location.pathname.startsWith("/users")
                    ? SidebarCSS.navBtnActive
                    : ""
                }`}
                onClick={handleUsers}
              >
                <FontAwesomeIcon
                  icon={faUserGroup}
                  style={{ marginRight: "10px" }}
                />
                {isOpen && "Users"}
              </button>
            </li>
            <li className={SidebarCSS.li}>
              <button
                className={`${SidebarCSS.navBtn} ${
                  location.pathname.startsWith("/assign")
                    ? SidebarCSS.navBtnActive
                    : ""
                }`}
                onClick={handleAssign}
              >
                <FontAwesomeIcon
                  icon={faUserTag}
                  style={{ marginRight: "10px" }}
                />
                {isOpen && "Assign"}
              </button>
            </li>
          </React.Fragment>
        )}
        <li className={SidebarCSS.li}>
          <button
            className={`${SidebarCSS.navBtn} ${
              location.pathname.startsWith("/profile")
                ? SidebarCSS.navBtnActive
                : ""
            }`}
            onClick={handleProfile}
          >
            <FontAwesomeIcon
              icon={faAddressCard}
              style={{ marginRight: "10px" }}
            />
            {isOpen && "Profile"}
          </button>
        </li>
        <li>
          <button className={SidebarCSS.navBtn} onClick={handleLogout}>
            <FontAwesomeIcon
              icon={faRightFromBracket}
              style={{ marginRight: "10px" }}
            />
            {isOpen && "Logout"}
          </button>
        </li>
      </ul>
      <button className={SidebarCSS.toggleBtn} onClick={toggleSidebar}>
        <FontAwesomeIcon
          icon={isOpen ? faAnglesLeft : faAnglesRight}
          style={{ fontSize: "28px" }}
        />
      </button>
    </div>
  );
}

export default Sidebar;
