import React, { useState, useEffect, useCallback } from "react";
import Sidebar from "../components/Sidebar";
import {
  userIdEnabledAccountApi,
  addUserIdEnabledAccountApi,
  deleteUserIdEnabledAccountApi,
  deleteAllUserIdEnabledAccountApi,
} from "../utilities/Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserTag,
  faSquarePlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import Loader from "../components/Loader.jsx";
import AssignCSS from "../css/Assign.module.css";
import ConfirmationModal from "../components/ConfirmationModal.jsx";

function Assign() {
  const [isLoading, setIsLoading] = useState(true);
  const [assignData, setAssignData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isActionInProgress, setIsActionInProgress] = useState(false);
  const [formData, setFormData] = useState({});
  const [loadingData, setLoadingData] = useState(true);
  const [isOpen, setIsOpen] = useState(true);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const fetchData = useCallback(async () => {
    try {
      const response = await userIdEnabledAccountApi();
      setAssignData(response.userid_enabled_ac);
      setIsLoading(false);
    } catch (error) {
      toast.error(`Error fetching data: ${error}`);
    } finally {
      setLoadingData(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
    document.documentElement.style.setProperty(
      '--sidebar-width',
      isOpen ? '250px' : '50px'
    );
    const intervalId = setInterval(() => {
      fetchData();
    }, 60000);
    return () => clearInterval(intervalId);
  }, [isOpen,fetchData]);

  const handleAdd = () => {
    setIsAddModalOpen(true);
  };

  const handleConfirmAdd = async () => {
    if (!formData.AccountId || !formData.UserId) {
      toast.error("Please enter all fields!");
      return;
    }

    setIsActionInProgress(true);
    try {
      const response = await addUserIdEnabledAccountApi(
        formData.AccountId,
        formData.UserId
      );
      if (response && response.message === "Values inserted successfully") {
        toast.success("Values inserted successfully");
        setIsAddModalOpen(false);
        setFormData({});
        fetchData();
      }
    } catch (error) {
      toast.error(`Error adding record: ${error}`);
    } finally {
      setIsActionInProgress(false);
    }
  };

  const handleDelete = () => {
    setIsDeleteModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    setIsActionInProgress(true);
    try {
      const idsToDelete = selectedRows.map((row) => row.Id);
      const response = await deleteUserIdEnabledAccountApi(idsToDelete);
      if (response && response.message === "Records deleted successfully") {
        toast.success("Records deleted successfully");
        setIsDeleteModalOpen(false);
        setSelectedRows([]);
        fetchData();
      }
    } catch (error) {
      toast.error("Error deleting records");
    } finally {
      setIsActionInProgress(false);
    }
  };

  const handleDeleteAll = async () => {
    setIsActionInProgress(true);
    try {
      const response = await deleteAllUserIdEnabledAccountApi();
      if (response && response.message === "All users deleted successfully") {
        toast.success("All records deleted successfully");
        setIsDeleteModalOpen(false);
        setSelectedRows([]);
        fetchData();
      }
    } catch (error) {
      toast.error("Error deleting all records");
    } finally {
      setIsActionInProgress(false);
    }
  };

  const handleModalClose = () => {
    setIsAddModalOpen(false);
    setIsDeleteModalOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleRowCheckboxChange = (row) => {
    if (selectedRows.includes(row)) {
      setSelectedRows(selectedRows.filter((item) => item !== row));
    } else {
      setSelectedRows([...selectedRows, row]);
    }
  };

  const handleSelectAll = () => {
    setSelectedRows(
      selectedRows.length === assignData.length ? [] : assignData
    );
  };

  return (
    <div className="MainDiv">
      <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
      <div className="MainDivContent">
        <h1 style={{ margin: "12px 0px 20px 0px" }}>
          <FontAwesomeIcon icon={faUserTag} style={{ marginRight: "10px" }} />
          Assign
        </h1>
        <div className={AssignCSS.btnContainer}>
          <div>
            <button className={AssignCSS.addBtn} onClick={handleAdd}>
              <FontAwesomeIcon
                icon={faSquarePlus}
                style={{ marginRight: "10px" }}
              />
              Add
            </button>
          </div>
          <div>
            <button
              className={AssignCSS.deleteBtn}
              onClick={handleDelete}
              disabled={selectedRows.length === 0}
            >
              <FontAwesomeIcon icon={faTrash} style={{ marginRight: "10px" }} />
              Delete
            </button>
          </div>
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          <div>
            <table>
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      checked={
                        selectedRows.length === assignData.length &&
                        assignData.length > 0
                      }
                      onChange={handleSelectAll}
                    />
                  </th>
                  <th>Id</th>
                  <th>Account Id</th>
                  <th>User Id</th>
                </tr>
              </thead>
              <tbody>
                {assignData.length === 0 && (
                  <tr>
                    <td colSpan="4">No records found</td>
                  </tr>
                )}
                {assignData.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <input
                        type="checkbox"
                        checked={selectedRows.includes(item)}
                        onChange={() => handleRowCheckboxChange(item)}
                      />
                    </td>
                    <td>{item.Id}</td>
                    <td>{item.AccountId}</td>
                    <td>{item.UserId}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <ConfirmationModal
          isOpen={isAddModalOpen}
          onClose={handleModalClose}
          questionText="Enter the below details to add a record:"
          confirmText="Add"
          cancelText="Cancel"
          onConfirm={handleConfirmAdd}
        >
          <form>
            <label className={AssignCSS.label}>
              Account Id
              <input
                type="text"
                name="AccountId"
                placeholder="Enter account id"
                value={formData.AccountId || ""}
                className={AssignCSS.input}
                onChange={handleChange}
              />
            </label>
            <label className={AssignCSS.label}>
              User Id
              <input
                type="text"
                name="UserId"
                placeholder="Enter user id"
                value={formData.UserId || ""}
                className={AssignCSS.input}
                onChange={handleChange}
              />
            </label>
          </form>
        </ConfirmationModal>
        <ConfirmationModal
          isOpen={isDeleteModalOpen}
          onClose={handleModalClose}
          questionText={
            selectedRows.length === assignData.length
              ? "Are you sure you want to delete all records?"
              : "Are you sure you want to delete selected records?"
          }
          confirmText="Confirm"
          cancelText="Cancel"
          onConfirm={
            selectedRows.length === assignData.length
              ? handleDeleteAll
              : handleConfirmDelete
          }
        >
          <p style={{ margin: "0px 0px 10px 0px" }}>
            {selectedRows.length === assignData.length
              ? "This action will permanently remove all records from our system. All associated data will be lost."
              : "This action will permanently remove the selected records from our system. All associated data will be lost."}
          </p>
        </ConfirmationModal>
        {isActionInProgress && <Loader />}
        {loadingData && <Loader />}
      </div>
    </div>
  );
}

export default Assign;
