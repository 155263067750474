import React, { useState, useEffect } from "react";
import Logger from "../utilities/Logger.js";
import Sidebar from "../components/Sidebar";
import { useNavigate } from "react-router-dom";
import { usersInfoApi, userProfileApi, deleteUserApi } from "../utilities/Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserGroup,
  faUserPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import Loader from "../components/Loader.jsx";
import UsersCSS from "../css/Users.module.css";
import ConfirmationModal from "../components/ConfirmationModal.jsx";

function Users() {
  const navigate = useNavigate();
  const ProfileData = JSON.parse(localStorage.getItem("profile"));
  const userDataFromUser = JSON.parse(localStorage.getItem("user"));
  const USERID = ProfileData ? ProfileData.UserId : userDataFromUser.UserId;
  const [isLoading, setIsLoading] = useState(false);
  const [userDeleted, setUserDeleted] = useState(false);
  const [users, setUsers] = useState([]);

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    document.documentElement.style.setProperty(
      '--sidebar-width',
      isOpen ? '250px' : '50px'
    );
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await usersInfoApi();
        const currentUserId = JSON.parse(
          localStorage.getItem("profile")
        ).UserId;
        const filteredUsers = response.filter(
          (user) => user.UserId !== currentUserId
        );
        setUsers(filteredUsers);
        const updateCurrentLoggedinUserData = await userProfileApi(USERID);
        localStorage.setItem(
          "profile",
          JSON.stringify(updateCurrentLoggedinUserData)
        );
      } catch (error) {
        toast.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [isOpen,USERID, userDeleted]);

  // Function to save user data to localStorage
  const handleEditProfileButton = (userData) => {
    localStorage.setItem("selectedUser", JSON.stringify(userData));
    const selectedUserData = JSON.parse(localStorage.getItem("selectedUser"));
    const UserId = selectedUserData.UserId;
    navigate(`/users/edit/${UserId}`, {
      state: {
        localStorageKey: "selectedUser",
        navigateOnSuccess: "/users",
        navigateOnBackButton: "/users",
      },
    });
  };

  // Function to save user data to localStorage
  const handleEditPasswordButton = (userData) => {
    localStorage.setItem("selectedUser", JSON.stringify(userData));
    const selectedUserData = JSON.parse(localStorage.getItem("selectedUser"));
    const UserId = selectedUserData.UserId;
    navigate(`/users/change-password/${UserId}`, {
      state: {
        localStorageKey: "selectedUser",
        logoutOnSuccess: false,
        navigateOnBackButton: "/users",
      },
    });
  };

  // Function to open confirmation modal
  const handleDeleteUser = (userData) => {
    localStorage.setItem("selectedUser", JSON.stringify(userData));
    setIsConfirmationModalOpen(true);
  };

  // Function to delete user
  const handleConfirmDelete = async () => {
    try {
      const selectedUserData = JSON.parse(localStorage.getItem("selectedUser"));
      const DeletUserId = selectedUserData.UserId;
      const response = await deleteUserApi(DeletUserId);
      if (response && response.message === "User deleted successfully") {

        toast.success("User deleted successfully");
        Logger.info(`User "${DeletUserId}" deleted By "${USERID}"`);
        setUserDeleted(true);
        setIsConfirmationModalOpen(false);
      }
    } catch (error) {
      toast.error("Error deleting user:", error);
    }
  };

  // Function to close confirmation modal
  const handleCloseDeleteModal = () => {
    setIsConfirmationModalOpen(false);
  };

  return (
    <div className="MainDiv">
      <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
      <div className="MainDivContent">
        <h1 style={{ margin: "12px 0px 20px 0px" }}>
          <FontAwesomeIcon icon={faUserGroup} style={{ marginRight: "10px" }} />
          Users
        </h1>
        {isLoading ? (
          <Loader />
        ) : (
          <div className={UsersCSS.MainContainer}>
            <div className={UsersCSS.btnConatiner}>
              <button
                className={UsersCSS.addUserBtn}
                onClick={() => navigate("/users/add")}
              >
                <FontAwesomeIcon
                  icon={faUserPlus}
                  style={{ marginRight: "5px" }}
                />
                Add User
              </button>
            </div>
            <div className={UsersCSS.cardContainer}>
              {users.map((user, index) => (
                <div
                  key={user.UserId}
                  className={UsersCSS.userCard}
                  style={{
                    marginRight: (index + 1) % 3 === 0 ? "0" : "20px", // Add marginRight only for every 3rd card
                  }}
                >
                  <div className={UsersCSS.userCardRight}>
                    <p className={UsersCSS.userName}>
                      {user.UserName}
                      <span className={UsersCSS.userRole}>
                        ({user.UserRole})
                      </span>
                    </p>
                    <p className={UsersCSS.userId}>
                      <b>User Id:</b> {user.UserId}
                    </p>
                    <p className={UsersCSS.userMail}>
                      <b>User Email:</b> {user.UserMail}
                    </p>
                    <button
                      className={UsersCSS.btn}
                      onClick={() => handleEditProfileButton(user)}
                    >
                      Edit Profile
                    </button>
                    <button
                      className={UsersCSS.btn}
                      onClick={() => handleEditPasswordButton(user)}
                    >
                      Forgot Password
                    </button>
                    <button
                      className={UsersCSS.deleteBtn}
                      onClick={() => handleDeleteUser(user)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        <ConfirmationModal
          isOpen={isConfirmationModalOpen}
          onClose={handleCloseDeleteModal}
          questionText={"Are you sure you want to delete this user?"}
          confirmText="Confirm"
          cancelText="Cancel"
          onConfirm={handleConfirmDelete}
        >
          <p style={{ margin: "0px 0px 10px 0px" }}>
            This action will permanently remove the user account from our
            system. All associated data will be lost.
          </p>
        </ConfirmationModal>
      </div>
    </div>
  );
}

export default Users;
