import axios from "axios";

export const axiosInstance = axios.create({
  baseURL: "https://samvitti-project-seven.vercel.app",
  headers: {
    "X-API-Key":
      process.env.REACT_APP_API_KEY || "3c0b1fee-b492-47a6-b8ac-084f8e7b74b9",
  },
});

export const axiosInstanceNews = axios.create({
  baseURL: "https://samvitti-project-seven.vercel.app",
  headers: {
    "X-API-Key":
      process.env.REACT_APP_API_KEY || "3c0b1fee-b492-47a6-b8ac-084f8e7b74b9",
  },
});
