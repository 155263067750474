import React, { useState, useEffect, useCallback } from "react";
import { fundsApi, nseAnnouncementsApi } from "../utilities/Api";
import Loader from "../components/Loader.jsx";
import { toast } from "react-toastify";
import Sidebar from "../components/Sidebar";
import FundsCSS from "../css/Funds.module.css";
import NewsCSS from "../css/News.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSackDollar,
  faSearch,
  faTags,
} from "@fortawesome/free-solid-svg-icons";
import ConfirmationModal from "../components/ConfirmationModal";

function Funds() {
  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");
  const [isLoading, setIsLoading] = useState(false);
  const [fundsData, setFundsData] = useState([]);
  const [type, setType] = useState("cash");
  const [searchQuery, setSearchQuery] = useState("");
  const [isOpen, setIsOpen] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newsData, setNewsData] = useState([]);
  const [newsLoading, setNewsLoading] = useState(false);
  const userData = JSON.parse(localStorage.getItem("user"));
  const USERID = userData ? userData.UserId : "";

  const handleColumnClick = (columnName) => {
    if (sortColumn === columnName) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(columnName);
      setSortDirection("asc");
    }
  };

  const sortData = (data) => {
    if (!sortColumn) return data; // No sorting if sortColumn is not set
    return data.sort((a, b) => {
      if (a[sortColumn] < b[sortColumn]) {
        return sortDirection === "asc" ? -1 : 1;
      }
      if (a[sortColumn] > b[sortColumn]) {
        return sortDirection === "asc" ? 1 : -1;
      }
      return 0;
    });
  };

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fundsApi(USERID, type, 10000);
      setFundsData(response.user_data);
    } catch (error) {
      toast.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  }, [USERID, type]);

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--sidebar-width",
      isOpen ? "250px" : "50px"
    );
    fetchData();
  }, [isOpen, fetchData]);

  const handleOptionChange = (option) => {
    setType(option);
  };

  const handleRowClick = async (item) => {
    setNewsLoading(true);
    try {
      const response = await nseAnnouncementsApi(item.symbol);
      if (response.Table.length !== 0) {
        setIsModalOpen(true);
        setNewsData(response.Table);
      } else {
        toast.error("No news data found for this symbol");
      }
    } catch (error) {
      toast.error("Error fetching news data:", error);
    } finally {
      setNewsLoading(false);
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const filteredFundsData = fundsData.filter((item) => {
    return (
      item.AccountName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.symbol.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.BuyAmount.toString().includes(searchQuery) ||
      item.BuyAvgPrice.toString().includes(searchQuery) ||
      item.quantity_remaining.toString().includes(searchQuery) ||
      item.realised_pl.toString().includes(searchQuery)
    );
  });

  const sortedData = sortData([...filteredFundsData]);

  return (
    <div className="MainDiv">
      <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
      <div className="MainDivContent">
        <h1 style={{ margin: "12px 0px" }}>
          <FontAwesomeIcon
            icon={faSackDollar}
            style={{ marginRight: "10px" }}
          />
          Funds
        </h1>
        <div className={FundsCSS.btnSearchContainer}>
          <div className={FundsCSS.searchContainer}>
            <input
              type="text"
              placeholder="Search"
              className={FundsCSS.searchInput}
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <FontAwesomeIcon icon={faSearch} className={FundsCSS.searchIcon} />
          </div>
          <div className={FundsCSS.btnContainer}>
            <button
              className={type === "cash" ? FundsCSS.btn : FundsCSS.btnActive}
              onClick={() => handleOptionChange("cash")}
            >
              EQ
            </button>
            <button
              className={type === "nse" ? FundsCSS.btn : FundsCSS.btnActive}
              onClick={() => handleOptionChange("nse")}
            >
              FNO
            </button>
          </div>
        </div>

        {/* Container for table */}
        <div className={FundsCSS.tablePaddingContainer}>
          <div className={FundsCSS.tableContainer}>
            {isLoading ? (
              <Loader />
            ) : (
              <table>
                <thead>
                  <tr>
                    <th onClick={() => handleColumnClick("AccountName")}>
                      Account Name
                    </th>
                    <th onClick={() => handleColumnClick("BuyAmount")}>
                      Buy Amount
                    </th>
                    <th onClick={() => handleColumnClick("BuyAvgPrice")}>
                      Buy Avg Price
                    </th>
                    <th>Org Date</th>
                    <th>Product</th>
                    <th>RO Avg Price</th>
                    <th>Stg Name</th>
                    <th onClick={() => handleColumnClick("quantity_remaining")}>
                      Quantity Remaining
                    </th>
                    <th onClick={() => handleColumnClick("realised_pl")}>
                      Realised PL
                    </th>
                    <th onClick={() => handleColumnClick("symbol")}>Symbol</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedData.length === 0 ? (
                    <tr className={FundsCSS.noRecordTr}>
                      <td colSpan={10} className={FundsCSS.noRecordsOnSearch}>
                        No records matching the search query!
                      </td>
                    </tr>
                  ) : (
                    sortedData.map((item, index) => (
                      <tr
                        key={`${item.AccountName}_${index}`}
                        className={type === "cash" ? FundsCSS.tableRow : ""}
                        onClick={
                          type === "cash" ? () => handleRowClick(item) : null
                        }
                      >
                        <td>{item.AccountName}</td>
                        <td>{item.BuyAmount}</td>
                        <td>{item.BuyAvgPrice}</td>
                        <td>{item.OrgDate}</td>
                        <td>{item.Product}</td>
                        <td>{item.ROAvgPrice}</td>
                        <td>{item.StgName}</td>
                        <td>{item.quantity_remaining}</td>
                        <td>{item.realised_pl}</td>
                        <td>{item.symbol}</td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
      <ConfirmationModal
        isOpen={isModalOpen}
        questionText="News for Symbol"
        onClose={() => setIsModalOpen(false)}
        cancelText="Close"
        height={"500"}
        width={"900"}
      >
        {newsLoading ? (
          <Loader />
        ) : (
          <div>
            <div className={NewsCSS.modalNewsCardContainer}>
              {newsData.map((newsItem) => (
                <div key={newsItem.dt} className={NewsCSS.newsCard}>
                  <div className={NewsCSS.newsCardContent}>
                    <a
                      href={newsItem.attchmntFile}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <h2 className={NewsCSS.slogname}>
                        {newsItem.attchmntText}
                      </h2>
                    </a>
                    <p className={NewsCSS.category}>
                      {newsItem.desc}
                      <FontAwesomeIcon
                        icon={faTags}
                        style={{ marginLeft: "5px" }}
                      />
                      &nbsp;&nbsp;{" "}
                      <b className={NewsCSS.company}>{newsItem.sm_name}</b>
                    </p>
                    <p className={NewsCSS.dateTimeAndScripCode}>
                      <b>Date & Time:</b>&nbsp; {newsItem.sort_date}
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <b>Symbol:</b>&nbsp;{newsItem.symbol}
                    </p>
                    &nbsp;&nbsp;
                    <p className={NewsCSS.dateTimeAndScripCode}>
                      <b>Source:</b>&nbsp;{newsItem.SOURCE}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </ConfirmationModal>
    </div>
  );
}

export default Funds;
