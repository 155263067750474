import React, { useState, useEffect } from "react";
import {
  getSectorNewsApi
} from "../utilities/Api";
import { toast } from "react-toastify";
import DashNews from "../css/DashNews.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTags } from "@fortawesome/free-solid-svg-icons";
import Loader from "../components/Loader";
import { v4 as uuidv4 } from "uuid";
import { excludedSubcategories } from "../utilities/UtilityFunctions";
import NewsCSS from "../css/News.module.css";

function DashSectorNews() {
  const [newsData, setNewsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Set to true initially

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getSectorNewsApi();
        setNewsData(response);
        setIsLoading(false); // Toggle loading off after initial API call
      } catch (error) {
        setIsLoading(false); // In case of error, also toggle off loading
        toast.error("Error fetching data:", error);
      } finally {
        setTimeout(fetchData, 10000);
      }
    };

    fetchData(); // Initial call

    return () => clearTimeout(); // Clear timeout on unmount
  }, []);

  return (
    <div>
      {isLoading && <Loader />}
      {!isLoading && newsData.length === 0 && (
        <p
          style={{
            fontSize: "26px",
            fontWeight: "bold",
          }}
        >
          No news to display!
        </p>
      )}
      <div className={DashNews.newsCardContainer}>
        {newsData.map((newsItem) => {
          const uniqueKey = uuidv4();
          if (!excludedSubcategories.includes(newsItem.SUBCATNAME)) {
            return (
              <div key={uniqueKey} className={DashNews.newsCard}>
                <div className={DashNews.newsCardContent}>
                  <a
                    href={`${newsItem.ATTACHMENTNAME}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <h2 className={DashNews.slogname}>{newsItem.NEWSSUB}</h2>
                  </a>
                  <p className={DashNews.category}>
                    {newsItem.SUBCATNAME}
                    <FontAwesomeIcon
                      icon={faTags}
                      style={{ marginLeft: "5px" }}
                    />
                    &nbsp;- {newsItem.COMPANYNAME}
                  </p>
                  <p className={NewsCSS.category}>{newsItem.DESCRIPTION}</p>
                  <p className={DashNews.dateTimeAndScripCode}>
                    <b>Date & Time:</b>&nbsp; {newsItem.News_submission_dt}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <b>Scripcode:</b>&nbsp;{newsItem.SCRIP_CD}
                  </p>
                </div>
              </div>
            );
          } else {
            return null;
          }
        })}
      </div>
    </div>
  );
}

export default DashSectorNews;
