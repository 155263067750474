import React from "react";
import ConfirmModalCSS from "../css/ConfirmationModal.module.css";

const ConfirmationModal = ({
  isOpen,
  onClose,
  questionText,
  confirmText,
  cancelText,
  onConfirm,
  children,
  height,
  width,
}) => {
  if (!isOpen) return null;

  const modalStyle = {
    height: height && height >= 800 ? "800px" : height ? `${height}px` : "auto",
    width: width ? `${width}px` : "400px",
  };

  const childrenContainerStyle = {
    maxHeight: height && height >= 800 ? "700px" : "auto",
    overflowY: height && height >= 800 ? "auto" : "auto",
  };

  return (
    <div className={ConfirmModalCSS.ModalOverlay}>
      <div className={ConfirmModalCSS.Modal} style={modalStyle}>
        <div className={ConfirmModalCSS.ModalBody}>
          <h3 className={ConfirmModalCSS.ModalTextQuestion}>{questionText}</h3>
          {children && <span className={ConfirmModalCSS.line}></span>}
          <div
            className={ConfirmModalCSS.ChildrenContainer}
            style={childrenContainerStyle}
          >
            {children}
          </div>
          <div className={ConfirmModalCSS.ButtonContainer}>
            {confirmText && (
              <button
                className={ConfirmModalCSS.ConfirmButton}
                onClick={onConfirm}
              >
                {confirmText}
              </button>
            )}
            {cancelText && (
              <button
                className={ConfirmModalCSS.CancelButton}
                onClick={onClose}
              >
                {cancelText}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
