import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faLock } from "@fortawesome/free-solid-svg-icons";
import LoginCSS from "../css/Login.module.css";
import { loginApi, userProfileApi } from "../utilities/Api.js";
import Loader from "../components/Loader.jsx";
import { toast } from "react-toastify";

const logo = "/samvitti-capital.png";

function Login() {
  const [formData, setFormData] = useState({
    userid: "",
    password: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const { userid, password } = formData;
  const navigate = useNavigate();

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user"));
    if (userData) {
      navigate("/dashboard");
    }
  }, [navigate]);

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await loginApi(userid, password);
      if (response && response.UserToken) {
        const profileResponse = await userProfileApi(userid);
        localStorage.setItem("profile", JSON.stringify(profileResponse));
        toast.success("Login successful!");
        localStorage.setItem("user", JSON.stringify(response));
        navigate("/dashboard");
      } else {
        toast.error("Invalid credentials.");
      }
    } catch (error) {
      setIsLoading(false);

      if (error.response && error.response.status === 400) {
        toast.error(error.response.data);
      } else {
        toast.error("An error occurred. Please try again later.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  // Display the loader when the API call is in progress
  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className={LoginCSS.fullbody}>
        <div className={LoginCSS.backgroundImage}></div>
        <div className={LoginCSS.overlay}></div>
        <div className={LoginCSS.login_box}>
          <form onSubmit={onSubmit}>
            <div className={LoginCSS.logoContainer}>
              <img src={logo} alt="Logo" className={LoginCSS.logo} />
            </div>
            <div className={LoginCSS.textbox}>
              <FontAwesomeIcon icon={faUser} className={LoginCSS.i} />
              <input
                className={LoginCSS.inputText}
                type="text"
                placeholder="Enter your userid"
                name="userid"
                value={userid}
                onChange={onChange}
                autoComplete="given-userid"
              />
            </div>

            <div className={LoginCSS.textbox}>
              <FontAwesomeIcon icon={faLock} className={LoginCSS.i} />
              <input
                className={LoginCSS.inputText}
                type="password"
                placeholder="Enter your password"
                name="password"
                value={password}
                onChange={onChange}
                autoComplete="current-password"
              />
            </div>
            <input type="submit" className={LoginCSS.btn} value="Submit" />
          </form>
        </div>
      </div>
    </>
  );
}

export default Login;
