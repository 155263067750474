import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import { userProfileApi } from "../utilities/Api";
import Loader from "../components/Loader.jsx";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import ProfileCSS from "../css/Profile.module.css";

function Profile() {
  const userData = JSON.parse(localStorage.getItem("user"));
  const profileData = JSON.parse(localStorage.getItem("profile"));
  const USERNAME = profileData ? profileData.UserName : userData.UserName;
  const USERROLE = profileData ? profileData.UserRole : userData.UserRole;
  const USERID = profileData ? profileData.UserId : userData.UserId;

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    document.documentElement.style.setProperty(
      '--sidebar-width',
      isOpen ? '250px' : '50px'
    );
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await userProfileApi(USERID);
        localStorage.setItem("profile", JSON.stringify(response));

        const user = JSON.parse(localStorage.getItem("user"));
        const profile = JSON.parse(localStorage.getItem("profile"));
        // Check if both user and profile exist and if their UserNames are different
        if (user && profile && user.UserName !== profile.UserName) {
          user.UserName = profile.UserName;
          localStorage.setItem("user", JSON.stringify(user));
        }
      } catch (error) {
        toast.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [isOpen,USERID]);

  function editDetails() {
    navigate(`/profile/edit/${USERID}`, {
      state: {
        localStorageKey: "profile",
        navigateOnSuccess: "/profile",
        navigateOnBackButton: "/profile",
      },
    });
  }

  function changePassword() {
    navigate(`/profile/change-password/${USERID}`, {
      state: {
        localStorageKey: "profile",
        logoutOnSuccess: true,
        navigateOnBackButton: "/profile",
      },
    });
  }
  return (
    <div className="MainDiv">
      <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
      <div className="MainDivContent">
        <div className={ProfileCSS.Container}></div>
        {isLoading ? (
          <Loader />
        ) : (
          <div>
            <h1 className={ProfileCSS.userName}>{USERNAME}</h1>
            <p className={ProfileCSS.userRole}>
              <FontAwesomeIcon icon={faStar} className={ProfileCSS.icon} />
              {USERROLE}
            </p>
            <h3 className={ProfileCSS.h3Text}>
              <b>User Id:</b> {USERID}
            </h3>
            <h3 className={ProfileCSS.h3Text}>
              <b>Email Address:</b> {profileData && profileData.UserMail}
            </h3>
            <button className={ProfileCSS.btn} onClick={editDetails}>
              Edit Details
            </button>
            <button className={ProfileCSS.btn} onClick={changePassword}>
              Change Password
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Profile;
