import React, { useState, useEffect } from "react";
import Logger from "../utilities/Logger";
import Sidebar from "../components/Sidebar";
import {
  bseAllNewsApi,
  getAllSymbolsApi,
  getNewsApi,
  getNewsHistoricalDataApi,
  addNewsApi,
  uploadFileApi
} from "../utilities/Api";
import { excludedSubcategories } from "../utilities/UtilityFunctions";
import { toast } from "react-toastify";
import NewsCSS from "../css/News.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSquarePlus,
  faNewspaper,
  faTags,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import ConfirmationModal from "../components/ConfirmationModal";
import Loader from "../components/Loader";
import { v4 as uuidv4 } from "uuid";

function Gnews() {
  const [newsData, setNewsData] = useState([]);
  const [isHistoricalModalOpen, setIsHistoricalModalOpen] = useState(false);
  const [isHistoricalModalApiData, setIsHistoricalModalApiData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [isOpen, setIsOpen] = useState(true);
  const [suggestions, setSuggestions] = useState([]);
  const [allSymbols, setAllSymbols] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isFileUpload, setIsFileUpload] = useState(false);
  const userData = JSON.parse(localStorage.getItem("user"));
  const profileData = JSON.parse(localStorage.getItem("profile"));
  const USERID = userData.UserId;
  const USERROLE =
    profileData && userData ? profileData.UserRole : userData.UserRole;
  const isAdminOrResearch = USERROLE === "ADMIN" || USERROLE === "RESEARCHER";

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  const [formData, setFormData] = useState({
    subcatname: "",
    newssub: "",
    symbol: "",
    attachmentname: "",
    description: "",
  });

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file.size > 15 * 1024 * 1024) {
      toast.error("File size cannot exceed more than 15MB");
    } else {
      setSelectedFile(file);
      toast.success(`Selected file: ${file.name}`);
    }
  };
  const handleSymbolInputChange = (e) => {
    const value = e.target.value;
    setFormData({ ...formData, symbol: value });
    if (!value) {
      setSuggestions([]);
      return;
    }
    const filteredSuggestions = allSymbols.filter((symbol) =>
      symbol.toLowerCase().startsWith(value.toLowerCase())
    ).slice(0, 5); 
    setSuggestions(filteredSuggestions);
  };

  const handleSuggestionClick = (suggestion) => {
    setFormData({ ...formData, symbol: suggestion });
    setSuggestions([]);
  };

  useEffect(() => {
    document.documentElement.style.setProperty(
      '--sidebar-width',
      isOpen ? '250px' : '50px'
    );
    const fetchSymbols = async () => {
      // Assuming getAllSymbolsApi is an async function that fetches the symbols
      const symbols = await getAllSymbolsApi();
      setAllSymbols(symbols);
    };
    const fetchData = async () => {
      try {
        const response = await bseAllNewsApi();
        const response1 = await getNewsApi();
        const updatedResponse = response.Table.map((item) => ({
          ...item,
          ATTACHMENTNAME: `${item.ATTACHMENTNAME}`,
        }));
        const mergedData = [...updatedResponse, ...response1];
        mergedData.sort(
          (a, b) =>
            new Date(b.News_submission_dt) - new Date(a.News_submission_dt)
        );
        setNewsData(mergedData);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        toast.error("Error fetching data:", error);
      }
    };

    fetchData();
    fetchSymbols();
    const intervalId = setInterval(fetchData, 10000);
    return () => clearInterval(intervalId);
  }, [isOpen, USERID, USERROLE]);

  const openHistoricalModal = async (scripcode) => {
    setIsHistoricalModalOpen(true);
    setIsLoading(true);
    try {
      const response = await getNewsHistoricalDataApi(scripcode);
      setIsHistoricalModalApiData(response.results.Table);
    } catch (error) {
      toast.error("Error fetching historical data:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleToggleChange = () => {
    setIsFileUpload(!isFileUpload);
  };

  const handleAddNewsSourceLink = async () => {
    if (!formData.subcatname || !formData.newssub || !formData.symbol) {
      toast.error("Please enter all fields");
      return;
    }

    setIsLoading(true);

    try {
      const response = await addNewsApi(
        formData.subcatname,
        formData.newssub,
        formData.symbol,
        formData.attachmentname,
        formData.description,
        profileData.UserId
      );

      if (response && response.message === "Record added successfully") {
        const response = await bseAllNewsApi();
        const response1 = await getNewsApi();
        const updateMergedData = [...response.Table, ...response1];
        updateMergedData.sort(
          (a, b) =>
            new Date(a.News_submission_dt) - new Date(b.News_submission_dt)
        );

        setNewsData(updateMergedData.slice(0, 1000));
        toast.success("News added successfully");
        Logger.info(`News Added By ${USERID}`);
        setIsAddModalOpen(false);
        setFormData({
          subcatname: "",
          newssub: "",
          symbol: "",
          attachmentname: "",
          description: "",
        });
      }
    } catch (error) {
      toast.error("Error adding news:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddNewsUploadFile = async () => {
    if (!formData.subcatname || !formData.newssub || !formData.symbol) {
      toast.error("Please enter all fields");
      return;
    }

    setIsLoading(true);

    try {
      let attachmentName = "";

      if (isFileUpload && selectedFile) {
        const uploadResponse = await uploadFileApi(selectedFile);
        if (!uploadResponse || !uploadResponse.file_url) {
          toast.error("Error uploading file");
          setIsLoading(false);
          return;
        }
        attachmentName = uploadResponse.file_url;
      }

      const response = await addNewsApi(
        formData.subcatname,
        formData.newssub,
        formData.symbol,
        attachmentName,
        formData.description,
        profileData.UserId
      );

      if (response && response.message === "Record added successfully") {
        const responseBSE = await bseAllNewsApi();
        const responseNews = await getNewsApi();
        const updatedResponse = responseBSE.Table.map((item) => ({
          ...item,
          ATTACHMENTNAME: `${item.ATTACHMENTNAME}`,
        }));
        const mergedData = [...updatedResponse, ...responseNews];

        mergedData.sort(
          (a, b) =>
            new Date(b.News_submission_dt) - new Date(a.News_submission_dt)
        );

        setNewsData(mergedData.slice(0, 1000));
        toast.success("News added successfully");
        Logger.info(`News Added By ${USERID}`);
        setIsAddModalOpen(false);
        setFormData({
          subcatname: "",
          newssub: "",
          symbol: "",
          attachmentname: "",
          description: "",
        });
      } else {
        toast.error("Error adding news");
      }
    } catch (error) {
      toast.error("Error adding news:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  // Function to filter news data based on search query and selected category
  const filteredNewsData = newsData.filter((newsItem) => {
    const matchesSearchQuery = newsItem.NEWSSUB.toLowerCase().includes(
      searchQuery.toLowerCase()
    );
    const matchesCategory = selectedCategory
      ? newsItem.SUBCATNAME === selectedCategory
      : true;
    return matchesSearchQuery && matchesCategory;
  });

  return (
    <div className="MainDiv">
      <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
      <div className="MainDivContent">
        <h1 style={{ margin: "12px 0px" }}>
          <FontAwesomeIcon icon={faNewspaper} style={{ marginRight: "10px" }} />
          Global News
        </h1>
        <div className={NewsCSS.btnConatiner}>
          <div style={{ position: "relative" }}>
            <input
              type="text"
              placeholder="Search"
              className={NewsCSS.searchInput}
              value={searchQuery}
              onChange={handleSearchChange}
              style={{ marginRight: "10px" }}
            />
            <FontAwesomeIcon icon={faSearch} className={NewsCSS.searchIcon} />

            <select
              name="subcatname"
              className={NewsCSS.inputDropdown}
              value={selectedCategory}
              onChange={handleCategoryChange}
            >
              <option value="">All</option>
              <option value="Board Meeting">Board Meeting</option>
              <option value="Company Update">Company Update</option>
              <option value="Result">Result</option>
              <option value="Corp Action">Corp Action</option>
              <option value="New Listing">New Listing</option>
              <option value="Insider Trading SAST">Insider Trading SAST</option>
              <option value="AGM/EGM">AGM/EGM</option>
            </select>
          </div>
          <div>
            {isAdminOrResearch && (
              <button
                className={NewsCSS.addNewsBtn}
                onClick={() => setIsAddModalOpen(true)}
              >
                <FontAwesomeIcon
                  icon={faSquarePlus}
                  style={{ marginRight: "10px" }}
                />
                Add News
              </button>
            )}
          </div>
        </div>
        {isLoading && <Loader />}
        <div className={NewsCSS.newsCardContainer}>
          {filteredNewsData.length === 0 ? (
            <p className={NewsCSS.NoNewsText}>No news to display &#x1F61E;</p>
          ) : (
            filteredNewsData.map((newsItem) => {
              if (!excludedSubcategories.includes(newsItem.SUBCATNAME)) {
                const uniqueKey = uuidv4();
                return (
                  <div key={uniqueKey} className={NewsCSS.GnewsCard}>
                    <div className={NewsCSS.newsCardContent}>
                      <a
                        href={`${newsItem.ATTACHMENTNAME}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <h2 className={NewsCSS.Gslogname}>
                          {newsItem.NEWSSUB}
                        </h2>
                      </a>
                      <p className={NewsCSS.Gcategory}>
                        {newsItem.SUBCATNAME}
                        <FontAwesomeIcon
                          icon={faTags}
                          style={{ marginLeft: "5px" }}
                        />
                         &nbsp;&nbsp; <b className={NewsCSS.company}>{newsItem.COMPANYNAME}</b>
                      </p>
                      <button
                        className={NewsCSS.GhistoricalBtn}
                        onClick={() => openHistoricalModal(newsItem.SCRIP_CD)}
                      >
                        <b>Historical Data</b>
                      </button>
                      <p className={NewsCSS.GdateTimeAndScripCode}>
                        <b>Date & Time:</b>&nbsp; {newsItem.News_submission_dt}
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <b>Scripcode:</b>&nbsp;{newsItem.SCRIP_CD}
                      </p>
                      &nbsp;&nbsp;
                      <p className={NewsCSS.dateTimeAndScripCode}>
                        <b>Source:</b>&nbsp;{newsItem.SOURCE}
                      </p>
                    </div>
                  </div>
                );
              } else {
                return null;
              }
            })
          )}
        </div>
      </div>
      <ConfirmationModal
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        questionText="Add News"
        confirmText="Add"
        cancelText="Cancel"
        onConfirm={
          isFileUpload ? handleAddNewsUploadFile : handleAddNewsSourceLink
        }
      >
        <form>
          <label className={NewsCSS.label}>
            Category
            <select
              name="subcatname"
              value={formData.subcatname}
              onChange={(e) =>
                setFormData({ ...formData, subcatname: e.target.value })
              }
              className={NewsCSS.input}
            >
              <option value="">Select Category</option>
              <option value="Board Meeting">Board Meeting</option>
              <option value="Company Update">Company Update</option>
              <option value="Result">Result</option>
              <option value="Corp Action">Corp Action</option>
              <option value="New Listing">New Listing</option>
              <option value="Insider Trading SAST">Insider Trading SAST</option>
              <option value="AGM/EGM">AGM/EGM</option>
            </select>
          </label>
          <label className={NewsCSS.label}>
            Headline
            <input
              type="text"
              name="newssub"
              value={formData.newssub}
              placeholder="Enter Headline"
              onChange={(e) =>
                setFormData({ ...formData, newssub: e.target.value })
              }
              className={NewsCSS.input}
            />
          </label>
          <label className={NewsCSS.label}>
            Description
            <input
              type="text"
              name="description"
              value={formData.description}
              placeholder="Enter Headline"
              onChange={(e) =>
                setFormData({ ...formData, description: e.target.value })
              }
              className={NewsCSS.input}
            />
          </label>

          <label className={NewsCSS.label}>
            <div className={NewsCSS.toggleContainer}>
              <span>Source Link</span>
              <label className={NewsCSS.toggleSwitch}>
                <input
                  type="checkbox"
                  checked={isFileUpload}
                  onChange={handleToggleChange}
                />
                <span className={NewsCSS.toggleSlider}></span>
              </label>
              <span>Upload File</span>
            </div>
          </label>
          {isFileUpload ? (
            <label className={NewsCSS.label}>
              File Upload:
              <input
                type="file"
                className={NewsCSS.input}
                onChange={handleFileChange}
              />
            </label>
          ) : (
            <label className={NewsCSS.label}>
              Source Link:
              <input
                type="text"
                name="attachmentname"
                className={NewsCSS.input}
                value={formData.attachmentname}
                placeholder="Enter Source Link"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    attachmentname: e.target.value,
                  })
                }
              />
            </label>
          )}
          <label className={NewsCSS.label}>
            Symbol
            <input
              type="text"
              className={NewsCSS.input}
              value={formData.symbol}
              onChange={handleSymbolInputChange}
              placeholder="Type to search symbols..."
            />
            {suggestions.length > 0 && (
              <ul>
                {suggestions.map((suggestion, index) => (
                  <li
                    key={index}
                    onClick={() => handleSuggestionClick(suggestion)}
                  >
                    {suggestion}
                  </li>
                ))}
              </ul>
            )}
          </label>
        </form>
        {isLoading && <Loader />}
      </ConfirmationModal>

      <ConfirmationModal
        isOpen={isHistoricalModalOpen}
        className="modalContent"
        onClose={() => setIsHistoricalModalOpen(false)}
        questionText="Historical Data"
        confirmText="Close"
        onConfirm={() => setIsHistoricalModalOpen(false)}
        height={"500"}
        width={"900"}
      >
        {isLoading ? (
          <Loader />
        ) : (
          <div>
            <div className={NewsCSS.modalNewsCardContainer}>
              {isHistoricalModalApiData.map((newsHistoricalItem) => {
                const uniqueKeyForHistoricalModal = uuidv4();
                return (
                  <div
                    key={uniqueKeyForHistoricalModal}
                    className={NewsCSS.newsCard}
                  >
                    <div className={NewsCSS.newsCardContent}>
                      <a
                        href={`${newsHistoricalItem.ATTACHMENTNAME}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <h2 className={NewsCSS.slogname}>
                          {newsHistoricalItem.NEWSSUB}
                        </h2>
                      </a>
                      <p className={NewsCSS.category}>
                        {newsHistoricalItem.SUBCATNAME}
                        <FontAwesomeIcon
                          icon={faTags}
                          style={{ marginLeft: "5px" }}
                        />
                      </p>
                      <p className={NewsCSS.dateTimeAndScripCode}>
                        <b>Date & Time:</b>&nbsp;{" "}
                        {newsHistoricalItem.News_submission_dt}
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <b>Scripcode:</b>&nbsp;{newsHistoricalItem.SCRIP_CD}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </ConfirmationModal>
    </div>
  );
}

export default Gnews;
